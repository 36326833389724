import { postCall } from "./axios";
import { getValueFromCookie } from "../utils/cookies";
import { v4 as uuidv4 } from "uuid";

export const searchByCityCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const payload = {
                "context": {
                    "domain": data?.domain,
                    "action": "search",
                    "country": "IND",
                    "city": `std:${data?.city || "079"}`,
                    "core_version": "1.2.0",
                    "bap_id": "api.mobibharat.com",
                    "bap_uri": "https://api.mobibharat.com/protocol/v1/",
                    "transaction_id": getValueFromCookie('transaction_id'),
                    "message_id": uuidv4(),
                    "timestamp": new Date().toISOString(),
                    "ttl": "PT30S"
                },
                "message": {
                    "intent": {
                        "fulfillment": {
                            "type": "Delivery"
                        },
                        "payment": {
                            "@ondc/org/buyer_app_finder_fee_type": "percent",
                            "@ondc/org/buyer_app_finder_fee_amount": "3"
                        }
                    }
                }
            };

            // indcr catalog X-ONDC-Search-Response: inc
            const incr_payload = {
                "context": {
                    "domain": data?.domain,
                    "action": "search",
                    "country": "IND",
                    // "city": `std:${data?.city}`,
                    "city": `*`,
                    "core_version": "1.2.0",
                    "bap_id": "api.mobibharat.com",
                    "bap_uri": "https://api.mobibharat.com/protocol/v1/",
                    "transaction_id": getValueFromCookie('transaction_id'),
                    "message_id": uuidv4(),
                    "timestamp": new Date().toISOString(),
                    "ttl": "PT30S"
                },
                "message": {
                    "intent": {
                        "payment": {
                            "@ondc/org/buyer_app_finder_fee_type": "percent",
                            "@ondc/org/buyer_app_finder_fee_amount": "3"
                        },
                        "tags": [
                            {
                                "code": "catalog_inc",
                                "list": [
                                    {
                                        "code": "mode",
                                        "value": "start"
                                    }
                                ]
                            }
                        ]
                    }
                }
            }
            
            const response = await postCall(`/clientApis/v2/search_by_city`, payload);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const searchByFulfillmentEndLocationCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_fulfillment_end_location`, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const getSellerInfo = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/info`, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const searchByCategoryCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_category`, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export const searchByItemCall = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await postCall(`/clientApis/v2/search_by_item`, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};
